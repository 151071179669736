import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledTileContainer = styled.div`
  height: 100%;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;

  ${mq.xsLarge`
    min-height: 412px;
  `}
`;

export const StyledPositionWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 320px;
  height: 320px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  overflow: hidden;
`;
