import styled, { css } from 'styled-components';

import { mq } from 'theme/media';

import { SliderArrowProps } from './SliderArrow';

export const StyledArrowWrapper = styled.div<{
  direction: SliderArrowProps['direction'];
}>`
  display: flex;

  ${({ direction }) =>
    direction === 'left' &&
    css`
      transform: rotate(-180deg);
    `}
`;

export const StyledArrowsWrapper = styled.div<{ isHidden: SliderArrowProps['isHidden'] }>`
  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;

export const StyledContainer = styled.button<{
  arrowsVariant: SliderArrowProps['arrowsVariant'];
  arrowsTop: SliderArrowProps['arrowsTop'];
  arrowsLeft: SliderArrowProps['arrowsLeft'];
  arrowsRight: SliderArrowProps['arrowsRight'];
  direction: SliderArrowProps['direction'];
  position: SliderArrowProps['position'];
  opacity: SliderArrowProps['opacity'];
  backgroundColor: SliderArrowProps['backgroundColor'];
}>`
  align-items: center;
  border: 0;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  cursor: pointer;
  display: flex;
  height: 72px;
  justify-content: center;
  outline: none;
  padding: 0;
  position: ${({ position }) => position};
  transition: opacity 0.3s linear;
  width: 68px;
  top: ${({ arrowsTop }) => arrowsTop};
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &.swiper-button-disabled,
  &.swiper-button-hidden {
    display: none;
  }

  &:hover {
    opacity: 1;
  }
  z-index: 99;
  opacity: ${({ opacity }) => opacity || 0.9};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};

  ${({ direction, arrowsVariant, theme }) =>
    direction === 'left'
      ? css`
          left: ${arrowsVariant === 'fixed' ? '15%' : 0};

          ${mq.xmLarge`
            left: ${arrowsVariant === 'fixed' ? '15%' : `calc(50% - (${theme.sizes.layoutWidth} / 2) - 68px)`};
          `}
        `
      : css`
          right: ${arrowsVariant === 'fixed' ? '15%' : 0};

          ${mq.xmLarge`
            right: ${arrowsVariant === 'fixed' ? '15%' : `calc(50% - (${theme.sizes.layoutWidth} / 2) - 68px)`};
          `}
        `}

  left: ${({ arrowsLeft }) => arrowsLeft};
  right: ${({ arrowsRight }) => arrowsRight};
`;
