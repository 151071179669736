import React, { forwardRef, SyntheticEvent, ForwardRefExoticComponent, RefAttributes } from 'react';
import Image from '@next/image';
import { useTheme } from 'styled-components';
import { rgba } from 'polished';

import { StyledArrowWrapper, StyledContainer, StyledArrowsWrapper } from './SliderArrow.styles';

export type SliderArrowProps = {
  direction: 'left' | 'right';
  arrowsVariant?: 'fittedToPageWidth' | 'fixed';
  arrowsTop?: string;
  arrowsLeft?: string;
  arrowsRight?: string;
  onClick?: (e: SyntheticEvent) => void;
  position?: 'absolute' | 'static';
  opacity?: number;
  backgroundColor?: string;
  isHidden?: boolean;
};

interface SliderArrow extends ForwardRefExoticComponent<SliderArrowProps & RefAttributes<HTMLButtonElement>> {
  StyledArrowsWrapper?: typeof StyledArrowsWrapper;
}

const SliderArrow: SliderArrow = forwardRef<HTMLButtonElement, SliderArrowProps>(
  (
    {
      onClick,
      direction,
      arrowsVariant,
      arrowsTop,
      arrowsLeft,
      arrowsRight,
      position = 'absolute',
      opacity = 0.3,
      backgroundColor
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <StyledContainer
        ref={ref}
        direction={direction}
        onClick={onClick}
        arrowsVariant={arrowsVariant}
        arrowsTop={arrowsTop}
        arrowsLeft={arrowsLeft}
        arrowsRight={arrowsRight}
        position={position}
        opacity={opacity}
        backgroundColor={backgroundColor || rgba(theme.colors.white, 0.6)}
      >
        <StyledArrowWrapper direction={direction}>
          <Image height={24} width={16} src="/img/slider-arrow.svg" alt="Slider Navigation" />
        </StyledArrowWrapper>
      </StyledContainer>
    );
  }
);

SliderArrow.StyledArrowsWrapper = StyledArrowsWrapper;

export default SliderArrow;
