import React, { FC } from 'react';
import Image from '@next/image';

import Typography from 'components/atoms/Typography/Typography';

import { StyledTileContainer, StyledPositionWrapper, StyledImageWrapper } from './PersonTile.styles';

type PersonTileProps = {
  src?: string;
  name: string;
  position: string;
  alt?: string;
};

export const PersonTile: FC<PersonTileProps> = ({ src, name, position, alt }) => (
  <StyledTileContainer>
    {src && (
      <StyledImageWrapper>
        <Image width={320} height={320} src={src} alt={alt} quality={100} unoptimized />
      </StyledImageWrapper>
    )}
    <Typography variant="h4" color="secondary">
      {name}
    </Typography>
    <StyledPositionWrapper>
      <Typography variant="body2" color="secondary">
        {position}
      </Typography>
    </StyledPositionWrapper>
  </StyledTileContainer>
);
