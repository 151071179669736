import React, { forwardRef, RefObject } from 'react';

import { StyledWrapper } from './SliderBullet.styles';

export type SliderBulletProps = {
  ref?: RefObject<HTMLDivElement>;
  variant?: 'sm' | 'lg';
};

const SliderBullet = forwardRef<HTMLDivElement, SliderBulletProps>(({ variant = 'lg' }, ref) => (
  <StyledWrapper variant={variant} ref={ref} />
));

export default SliderBullet;
